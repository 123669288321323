import {defineStore} from 'pinia';
import {useMenusStore} from "@/new-menu/stores/menus.store";
import API from "@/shared/helpers/API";
import {useCartStore} from "@/new-menu/stores/cart.store";
import {getRandomAlphanumericString} from "@/shared/helpers/strings";
import {formatDateTimeWithSeconds} from "@/shared/helpers/dates";

export const useEvsStore = defineStore('evs', {
	state: () => ({
		queuedEvents: [],
		updatableEvents: [],
		updateServerEvtsInterval: null,
		sessionToken: getSessionToken(),
		visitToken: getVisitToken(),
		startDate: new Date(),
		elapsedTimeBeforeUnload: 0,
	}),
	getters: {

	},
	actions: {
		updatePageVt(menuId, callback = null) {
			let menusStore = useMenusStore();
			if(menusStore.forceMenuId !== null) {
				return;
			}
			API.init().updatePageVt(menusStore.business.business_id, menuId, menusStore.roomTableId, menusStore.roomId, (r) => {
				callback && callback(r);
			});
		},
		initEvents() {

			let sessionQueuedEvents = sessionStorage.getItem('queuedEvents');
			if(sessionQueuedEvents) {
				this.queuedEvents = JSON.parse(sessionQueuedEvents);
			}

			if(this.updateServerEvtsInterval) {
				clearInterval(this.updateServerEvtsInterval);
			}
			this.updateServerEvtsInterval = setInterval(this.updateServerEvts, 4000+Math.floor(Math.random() * 2000));

			window.addEventListener('beforeunload', this.beforeUnload, {once: true});
			this.initTimeSpent();

		},
		updateServerEvts(callback = null) {
			let menusStore = useMenusStore();
			if(menusStore.forceMenuId !== null) {
				return;
			}
			let updateEvents = this.updatableEvents.filter((event) => {return event.toUpdate;});
			let eventsToSend = this.queuedEvents.concat(updateEvents);
			if(eventsToSend && eventsToSend.length) {
				let sendingEvents = JSON.stringify(eventsToSend);
				this.queuedEvents = [];
				updateEvents.forEach((event) => {event.toUpdate = false;});
				let checkRoomTableCalls = !!menusStore.enabledRoomTableCalls;
				API.init().updatePageEvs(sendingEvents, menusStore.business.business_id, menusStore.selectedMenuId, menusStore.roomTableId, this.sessionToken, this.visitToken, checkRoomTableCalls, (r) => {
					sessionStorage.setItem('queuedEvents', JSON.stringify(this.queuedEvents));
					if(callback) {
						callback();
					}
					if(r && r.keys && Array.isArray(r.keys)) {
						this.updateEventsKeys(r.keys);
					}
				});
			}
		},
		updateEventsKeys(keys) {
			keys.forEach((key) => {
				if(key.name === "time") {
					let timeEvent = this.updatableEvents.find((event) => { return event.name === 'time' && event.action === 'time-menu'; });
					timeEvent.dbId = key.id;
					timeEvent.dbKey = key.key;
				}
			});
		},
		enqueueEvent(eventName, eventAction = null, eventValue = null) {

			this.queuedEvents.push({
				name: eventName,
				action: eventAction,
				value: eventValue,
				date: formatDateTimeWithSeconds(new Date())
			});
			sessionStorage.setItem('queuedEvents', JSON.stringify(this.queuedEvents));

		},
		beforeUnload() {
			clearInterval(this.updateServerEvtsInterval);
			this.updateServerEvts();
		},
		initTimeSpent() {


			let hidden = null;
			let visibilityChange = null;
			if(typeof document.hidden !== "undefined" ){
				hidden = "hidden";
				visibilityChange = "visibilitychange";
			}else if ( typeof document.msHidden !== "undefined" ){
				hidden = "msHidden";
				visibilityChange = "msvisibilitychange";
			}

			setInterval(() => {

				let documentIsHidden = document[hidden];
				if(!documentIsHidden) {
					let event = this.updatableEvents.find((event) => { return event.name === 'time' && event.action === 'time-menu'; });
					let eventFound = !!event;
					if(!eventFound) {
						event = {
							name: 'time',
							action: 'time-menu',
							date: formatDateTimeWithSeconds(new Date()),
						};
					}
					event.value = Number((((new Date() - this.startDate) / 1000) + this.elapsedTimeBeforeUnload).toFixed(1));
					event.toUpdate = true;
					if(!eventFound) {
						this.updatableEvents.push(event);
					}
				}

			}, 8000+Math.floor(Math.random() * 4000));

			if(hidden) {

				let documentIsHidden = document[hidden];
				document.addEventListener(visibilityChange, () => {
					if(documentIsHidden !== document[hidden]) {
						if( document[hidden] ){
							// Window is inactive
							this.elapsedTimeBeforeUnload += (new Date() - this.startDate) / 1000;
							this.startDate = new Date();
						}else{
							// Window is active
							this.startDate = new Date();
						}
						documentIsHidden = document[hidden];
					}
				});

			}

		},
	}
});

function getSessionToken() {
	let sessionToken = sessionStorage.getItem('sessionToken');
	if(!sessionToken) {
		sessionToken = getRandomAlphanumericString(20);
		sessionStorage.setItem('sessionToken', sessionToken);
	}
	return sessionToken;
}

function getVisitToken() {
	return getRandomAlphanumericString(20);
}
