import {defineStore} from 'pinia';
import {useMenusStore} from "@/new-menu/stores/menus.store";
import API from "@/shared/helpers/API";
import {useCartStore} from "@/new-menu/stores/cart.store";
import {useLanguageStore} from "@/shared/stores";
import {useEvsStore} from "@/new-menu/stores/evs.store";

export const useTranslationStore = defineStore('translation', {
	state: () => ({

	}),
	getters: {

	},
	actions: {
		selectLanguage() {

			let languageStore = useLanguageStore();
			let menusStore = useMenusStore();
			let lang = languageStore.lang;
			if(!lang) {
				return;
			}
			let languageInterface = menusStore.languages.find(language => language.language_code === lang);
			if(!languageInterface) {
				return;
			}

			if(languageInterface.language_id !== menusStore.businessLanguage.language_id) {
				useEvsStore().enqueueEvent('translate', lang, "automatic");
				this.translateMenus(languageInterface, () => {
					menusStore.currentLanguageId = languageInterface.language_id;
				});
			} else {
				menusStore.currentLanguageId = menusStore.businessLanguage.language_id;
			}


		},
		translateMenus(language, callback = null) {

			let menusToSend = [];
			let categoriesToSend = [];
			let itemsToSend = [];
			let itemsListsToSend = [];
			let itemsListsProductsToSend = [];

			let menusStore = useMenusStore();

			for(let i = 0; i < menusStore.menus.length; i++) {

				let menu = menusStore.menus[i];
				menusToSend.push(menu.menu_id);

				for(let j = 0; j < menu.categories.length; j++) {

					let category = menu.categories[j];
					categoriesToSend.push(category.menu_category_id);

					for(let k = 0; k < category.items.length; k++) {

						let item = category.items[k];
						itemsToSend.push(item.menu_item_id);

						if(item.loadedExtras) {

							for(let l = 0; l < item.lists.length; l++) {

								let list = item.lists[l];
								itemsListsToSend.push(list.menu_item_list_id);

								for(let m = 0; m < list.products.length; m++) {

									let product = list.products[m];
									itemsListsProductsToSend.push(product.menu_item_list_product_id);

								}

							}
						}

					}

				}

			}

			let cartStore = useCartStore();
			for(let i = 0; i < cartStore.cart.length; i++) {
				let cartItem = cartStore.cart[i];
				if(cartItem.products) {
					for(let j = 0; j < cartItem.products.length; j++) {
						let product = cartItem.products[j];
						itemsListsProductsToSend.push(product.menu_item_list_product_id);
					}
				}
			}

			API.init().translateMenu(menusStore.business.business_id, menusStore.businessLanguage.language_code, language.language_code, itemsToSend, categoriesToSend, menusToSend, menusStore.business.description, menusStore?.room?.cover_charge_name, itemsListsProductsToSend, itemsListsToSend, (r) => {

				if(r && r.result && r.result === "OK") {


					for(let j = 0; j < r.translations.length; j++) {
						if (Number(r.translations[j].language_id_one) !== menusStore.businessLanguage.language_id || Number(r.translations[j].language_id_two) !== language.language_id) {
							delete r.translations[j];
						}
					}

					this.substituteTranslations(r.translations);


					callback && callback("OK");
				} else if(r && r.result && r.details === "menu_too_big") {
					callback && callback("menu_too_big");
				} else {
					callback && callback("error");
				}

			});

		},
		translateItemExtras(language, item, callback = null) {

			if(item.loadedExtras) {

				let itemsListsToSend = [];
				let itemsListsProductsToSend = [];

				for(let l = 0; l < item.lists.length; l++) {

					let list = item.lists[l];
					itemsListsToSend.push(list.menu_item_list_id);

					for(let m = 0; m < list.products.length; m++) {

						let product = list.products[m];
						itemsListsProductsToSend.push(product.menu_item_list_product_id);

					}

				}

				let menusStore = useMenusStore();

				API.init().translateMenu(menusStore.business.business_id, menusStore.businessLanguage.language_code, language.language_code, [item.menu_item_id], [], [], null, null, itemsListsProductsToSend, itemsListsToSend, (r) => {

					if(r && r.result && r.result === "OK") {

						for(let j = 0; j < r.translations.length; j++) {
							if (Number(r.translations[j].language_id_one) !== menusStore.businessLanguage.language_id || Number(r.translations[j].language_id_two) !== language.language_id) {
								delete r.translations[j];
							}
						}

						this.substituteTranslationsItem(r.translations, item);

						callback && callback("OK");
					} else if(r && r.result && r.details === "menu_too_big") {
						callback && callback("menu_too_big");
					} else {
						callback && callback("error");
					}

				});

			} else {
				callback && callback("not_loaded");
			}

		},
		substituteTranslations(translations) {

			let menusStore = useMenusStore();

			if(menusStore.business.description || menusStore?.room?.cover_charge_name) {
				let triggerCounter = 0;
				let triggerTarget = 0;
				if(menusStore.business.description) {
					triggerTarget++;
				}
				if(menusStore?.room?.cover_charge_name) {
					triggerTarget++;
				}
				for(let j = 0; j < translations.length; j++) {
					let translationString = translations[j];
					if(translationString.string_one === menusStore.business.description) {
						menusStore.business.descriptionTranslation = translationString.string_two;
						triggerCounter++;
					}
					if(translationString.string_one === menusStore?.room?.cover_charge_name) {
						menusStore.room.cover_charge_nameTranslation = translationString.string_two;
						triggerCounter++;
					}
					if(triggerCounter >= triggerTarget) {
						break;
					}
				}
			}

			for(let i = 0; i < menusStore.menus.length; i++) {

				let menu = menusStore.menus[i];

				for(let j = 0; j < translations.length; j++) {

					let translationString = translations[j];

					if(translationString.string_one === menu.name) {
						menu.nameTranslation = translationString.string_two;

						break;
					}

				}

			}


			let allCategories = menusStore.menus.map(menu => menu.categories).flat();
			for(let i = 0; i < allCategories.length; i++) {

				let category = allCategories[i];
				let breakCounter = 0;
				let breakTarget = 1;
				if(category.description) {
					breakTarget++;
				}

				for(let j = 0; j < translations.length; j++) {

					let translationString = translations[j];
					if(translationString.string_one === category.name) {

						category.nameTranslation = translationString.string_two;
						breakCounter++;
					}

					if(category.description && translationString.string_one === category.description) {
						category.descriptionTranslation = translationString.string_two;
						breakCounter++;
					}

					if(breakCounter >= breakTarget) {
						break;
					}

				}

			}


			let allProducts = allCategories.map(category => category.items).flat();
			for(let i = 0; i < allProducts.length; i++) {

				let item = allProducts[i];
				this.substituteTranslationsItem(translations, item);

			}

			let cartStore = useCartStore();
			for(let i = 0; i < cartStore.cart.length; i++) {
				let cartItem = cartStore.cart[i];
				if(cartItem.products) {
					for(let j = 0; j < cartItem.products.length; j++) {
						let product = cartItem.products[j];

						for(let k = 0; k < translations.length; k++) {
							let translationString = translations[k];
							if(translationString.string_one === product.product_name) {
								product.product_nameTranslation = translationString.string_two;
								break;
							}
						}

					}
				}
			}

		},
		substituteTranslationsItem(translations, item) {

			for(let j = 0; j < translations.length; j++) {

				let translationString = translations[j];
				let triggerCounter = 0;
				let triggerTarget = 1;

				if(item.ingredients) {
					triggerTarget++;
				}
				if(item.loadedExtras) {
					if(item.materials) {
						triggerTarget++;
					}
					if(item.long_description) {
						triggerTarget++;
					}
				}

				if(translationString.string_one === item.name) {
					item.nameTranslation = translationString.string_two;
					triggerCounter++;
				}

				if(item.ingredientsFull) {
					let ingredients = item.ingredientsFull;
					if(ingredients.length >= 490) {
						ingredients = ingredients.substring(0, 490) + "...";
					}
					if(translationString.string_one === ingredients) {
						item.ingredientsFullTranslation = translationString.string_two;
						item.ingredientsTranslation = translationString.string_two.substring(0, 107) + "...";
						triggerCounter++;
					}
				} else if(item.ingredients) {
					if(translationString.string_one === item.ingredients) {
						item.ingredientsTranslation = translationString.string_two;
						triggerCounter++;
					}
				}

				if(item.loadedExtras && item.materials && translationString.string_one === item.materials) {
					item.materialsTranslation = translationString.string_two;
					triggerCounter++;
				}
				if(item.loadedExtras && item.long_description && translationString.string_one === item.long_description) {
					item.long_descriptionTranslation = translationString.string_two;
					triggerCounter++;
				}

				if(triggerCounter >= triggerTarget) {
					break;
				}

			}

			if(item.loadedExtras && item.lists) {

				for(let k = 0; k < item.lists.length; k++) {

					let list = item.lists[k];

					for(let j = 0; j < translations.length; j++) {

						let translationString = translations[j];
						if(translationString.string_one === list.name) {
							list.nameTranslation = translationString.string_two;
							break;
						}

					}

					if(list.products) {

						for(let l = 0; l < list.products.length; l++) {

							let product = list.products[l];

							for(let j = 0; j < translations.length; j++) {

								let translationString = translations[j];
								if(translationString.string_one === product.product_name) {
									product.product_nameTranslation = translationString.string_two;
									break;
								}

							}

						}

					}

				}
			}

		},
		resetTranslations() {

			let menusStore = useMenusStore();

			menusStore.business.descriptionTranslation = menusStore.business.description;
			if(menusStore?.room && menusStore?.room?.cover_charge_name) {
				menusStore.room.cover_charge_nameTranslation = menusStore.room.cover_charge_name;

			}

			menusStore.menus.forEach(menu => {
				menu.nameTranslation = menu.name;
				menu.categories.forEach(category => {
					category.nameTranslation = category.name;
					category.descriptionTranslation = category.description;
					category.items.forEach(item => {
						item.nameTranslation = item.name;
						item.ingredientsTranslation = item.ingredients;
						item.materialsTranslation = item.materials;
						item.long_descriptionTranslation = item.long_description;
						if(item.lists) {
							for(let j = 0; j < item.lists.length; j++) {
								let list = item.lists[j];
								list.nameTranslation = list.name;
								if(list.products) {
									for(let k = 0; k < list.products.length; k++) {
										let product = list.products[k];
										product.product_nameTranslation = product.product_name;
									}
								}
							}
						}
					});
				});
			});

			let cartStore = useCartStore();
			for(let i = 0; i < cartStore.cart.length; i++) {
				let cartItem = cartStore.cart[i];
				if(cartItem.products) {
					for(let j = 0; j < cartItem.products.length; j++) {
						let product = cartItem.products[j];
						product.product_nameTranslation = product.product_name;
					}
				}
			}
		}
	}
});
